






































































































import { RouterNames } from "@/router/routernames";
import { ejercicio } from "@/shared/dtos/ejercicio";
import EjercicioModule from "@/store/modules/ejercicio-module";
import entrevistaModule from "@/store/modules/entrevista-module";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class EjercicioMenu extends Vue {
  public search: string = "";
  public dialog: boolean = false;
  public add_ejercicio: ejercicio = new ejercicio();
  public created() {
    if (EjercicioModule.ejercicios.length === 0) {
      EjercicioModule.getejercicios();
      entrevistaModule.getultimaentrevista();
    }
  }
  public get ejercicios() {
    if (this.search === "") {
      return EjercicioModule.ejercicios;
    }
    return EjercicioModule.ejercicios.filter((x) =>
      x.nombre_ejercicio.toLowerCase().includes(this.search.toLowerCase())
    );
  }

  public get stilo() {
    let h = this.$vuetify.breakpoint.height - 200;
    return "height:" + h + "px;overflow: auto;";
  }

  public CloseDialog(save: boolean) {
    if (save) {
      let ids: number[] = EjercicioModule.ejercicios.map((x) => x.id);
      this.add_ejercicio.id = Math.max(...ids) + 1;
      /*EjercicioModule.AddEjericicio(
        Object.assign(new EjerciciosDto(), this.add_ejercicio)
      );*/
      this.add_ejercicio.nombre_ejercicio = "";
    }
    this.dialog = false;
  }
  public openficha(ejercicio: ejercicio) {
    this.$router.push({
      name: RouterNames.ficha_ejercicio,
      params: { id: ejercicio.id },
    });
  }
}
